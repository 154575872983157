/**
 * Firestore Collections
 *
 * Here we export our Firestore collection names so that we can reuse them
 * across both client and server libraries
 *
 */

export const USERS_COLLECTION = `users`;
export const ORGANIZATIONS_COLLECTION = `organizations`;
export const INVITES_COLLECTION = `invites`;

export const collections = {
  common: {
    COMMENTS: 'comments',
    ERRORS: 'errors',
    LIKES: 'likes',
    VOTES: 'votes',
  },
  EMAILS: 'emails',
  INTEGRATIONS_PROVIDERS: 'integrationsProviders',
  organizations: {
    AWARDS: 'awards',
    BUCKETS: 'buckets',
    buckets: {
      FILES: 'files',
    },
    COMMUNITIES: 'communities',
    communities: {
      THREADS: 'threads',
      threads: {
        COMMENTS: 'comments',
      },
    },
    ENTITIES: 'entities',
    entities: {
      ENTITY_DATA: 'entityData',
    },
    FEED_POSTS: 'feedPosts',
    IMPORTS: 'imports',
    IDEAS: 'ideas',
    ideas: {
      COMMENTS: 'comments',
    },
    INTEGRATIONS: 'integrations',
    INVITES: 'invites',
    OPEN_ROLES: 'openRoles',
    PERMISSIONS: 'permissions',
    PIPELINES: 'pipelines',
    PIPELINES_PACKETS: 'pipelinesPackets',
    PIPELINES_NODES_USAGE: 'pipelinesNodesUsage',
    pipelines_packets: {
      LIFE_CYCLES: 'packetsLifeCycles',
      REVIEWS: 'reviews',
    },
    PIPELINES_QUEUES: 'pipelinesQueues',
    USER_PROFILES: 'userProfiles',
    USER_PROFILE_TAGS: 'userProfileTags',
    userProfiles: {
      AWARDS: 'awards',
      awards: {
        RECEIVED: 'received',
      },
      COMMUNITIES: 'communities',
      communities: {
        SUBSCRIBED: 'subscribed',
      },
      USER_NOTIFICATIONS: 'userNotifications',
    },
  },
  ORGANIZATIONS: 'organizations',
  USERS: 'users',
};
